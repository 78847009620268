<template>
    <div class="dr">
        <div class="t-container">
            <div class="tab">
                <div class="title">
                    {{ $i18n.t('drDetails.subject1') }}
                </div>
                <div class="fill">
                    <el-form ref="form" :rules="rules" :model="form" label-width="220px" size="mini">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title1_1')">
                                    <el-input v-model="form.dealBusinessType" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title1_2')" prop="projectName">
                                    <el-input v-model="form.projectName"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title1_3')" prop="companyName">
                                    <template v-if="user.identity === 3 && isAuthority">
                                        <el-select v-model="form.companyName" style="width: 100%;">
                                            <el-option v-for="item in companys" :key="item" :label="item" :value="item"></el-option>
                                        </el-select>
                                    </template>
                                    <template v-else>
                                        <span>{{ form.companyName }}</span>
                                    </template>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title1_4')" prop="winOdds">
                                    <el-input v-model="form.winOdds"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title1_5')">
                                    <!-- <el-input v-model="form.expectedDate"></el-input> -->
                                    <el-date-picker  v-model="form.expectedDate" type="date" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title1_6')">
                                    <!-- <el-input v-model="form.publicTender"></el-input> -->
                                    <el-radio v-model="form.publicTender" label="yes">YES</el-radio>
                                    <el-radio v-model="form.publicTender" label="no">NO</el-radio>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title1_7')">
                                    <el-date-picker  v-model="form.startFrom" type="date" value-format="yyyy-MM-dd" disabled style="width: 100%;"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title1_8')">
                                    <el-date-picker  v-model="form.validUntil" type="date" value-format="yyyy-MM-dd" disabled style="width: 100%;"></el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-form-item :label="$i18n.t('drDetails.title1_9')" prop="dealDescription">
                                    <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 10}"  v-model="form.dealDescription"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item :label="$i18n.t('drDetails.title1_10')">
                                    <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 10}"  v-model="form.dealBackground"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-form-item :label="$i18n.t('drDetails.title1_11')" prop="mangerEmail">
                                <el-input v-model="form.mangerEmail"></el-input>
                            </el-form-item>
                        </el-row>
                    </el-form>
                </div>
            </div>
            <div class="tab">
                <div class="title">
                    {{ $i18n.t('drDetails.subject2') }}
                </div>
                <div class="fill">
                    <div class="table-content">
                        <el-table :data="drProjectProgress" style="width: 100%" height="100%">
                            <el-table-column type="index" width="80">
                                <template slot="header">
                                    <div style="height: 100%;">
                                        <i class="el-icon-plus" style="font-size: 20px; font-weight: 700; color: #c7000b;" @click="addRow('drProjectProgress')"></i>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$i18n.t('drDetails.title2_1')" width="100">
                                <template slot-scope="{row, $index}">
                                    <div class="center-layout">
                                        <i class="el-icon-delete" @click="deleteRow('drProjectProgress', row, $index)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="projectProgress" :label="$i18n.t('drDetails.title2_2')" width="">
                                <template slot-scope="{row}">
                                    <div class="center-layout">
                                        <el-input type="text" v-model="row.projectProgress" v-if="row.status"></el-input>
                                        <div v-else>{{ row.projectProgress }}</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="help" :label="$i18n.t('drDetails.title2_3')" width="">
                                <template slot-scope="{row}">
                                    <div class="center-layout">
                                        <el-input type="text" v-model="row.help" v-if="row.status"></el-input>
                                        <div v-else>{{ row.help }}</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="createdDate" :label="$i18n.t('drDetails.title2_4')" width="140">
                                <template slot-scope="{row}">
                                    <div class="center-layout">
                                        <div v-if="!row.status">{{ row.createdDate }}</div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
            <div class="tab">
                <div class="title">
                    {{ $i18n.t('drDetails.subject3') }}
                </div>
                <div class="fill">
                    <div class="table-content">
                        <el-table :data="drCompetitors" style="width: 100%" height="100%">
                            <el-table-column type="index" width="80">
                                <template slot="header">
                                    <div style="height: 100%;">
                                        <i class="el-icon-plus" style="font-size: 20px; font-weight: 700; color: #c7000b;" @click="addRow('drCompetitors')"></i>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$i18n.t('drDetails.title3_1')" width="100">
                                <template slot-scope="{row, $index}">
                                    <div class="center-layout">
                                        <i class="el-icon-delete" @click="deleteRow('drCompetitors', row, $index)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="competitorName" :label="$i18n.t('drDetails.title3_2')" width="">
                                <template slot-scope="{row}">
                                    <div class="center-layout">
                                        <el-input type="text" v-model="row.competitorName" v-if="row.status"></el-input>
                                        <div v-else>{{ row.competitorName }}</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="competitorProduct" :label="$i18n.t('drDetails.title3_3')" width="">
                                <template slot-scope="{row}">
                                    <div class="center-layout">
                                        <el-input type="text" v-model="row.competitorProduct" v-if="row.status"></el-input>
                                        <div v-else>{{ row.competitorProduct }}</div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
            <div class="tab">
                <div class="title">
                    {{ $i18n.t('drDetails.subject4') }}
                </div>
                <div class="fill">
                    <div class="table-content">
                        <el-table :data="drProducts" style="width: 100%" height="100%">
                            <el-table-column type="index" width="80">
                                <template slot="header">
                                    <div style="height: 100%;">
                                        <i class="el-icon-plus" style="font-size: 20px; font-weight: 700; color: #c7000b;" @click="addRow('drProducts')"></i>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$i18n.t('drDetails.title4_1')" width="100">
                                <template slot-scope="{row, $index}">
                                    <div class="center-layout">
                                        <i class="el-icon-delete" @click="deleteRow('drProducts', row, $index)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="productType" :label="$i18n.t('drDetails.title4_2')" width="">
                                <template slot-scope="{row}">
                                    <div class="center-layout">
                                        <el-select v-model="row.productType" style="width: 100%;" v-if="row.status">
                                            <el-option label="Camera" value="Camera"></el-option>
                                            <el-option label="NVR/XVR" value="NVR/XVR"></el-option>
                                            <el-option label="IVS" value="IVS"></el-option>
                                            <el-option label="VMS" value="VMS"></el-option>
                                            <el-option label="Controller" value="Controller"></el-option>
                                            <el-option label="Other" value="Other"></el-option>
                                        </el-select>
                                        <div v-else>{{ row.productType }}</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="series" :label="$i18n.t('drDetails.title4_3')" width="">
                                <template slot-scope="{row}">
                                    <div class="center-layout">
                                        <el-input type="text" v-model="row.series" v-if="row.status"></el-input>
                                        <div v-else>{{ row.series }}</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="productName" :label="$i18n.t('drDetails.title4_4')" width="">
                                <template slot-scope="{row}">
                                    <div class="center-layout">
                                        <el-input type="text" v-model="row.productName" v-if="row.status"></el-input>
                                        <div v-else>{{ row.productName }}</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="estimatedQuantity" :label="$i18n.t('drDetails.title4_5')" width="">
                                <template slot-scope="{row}">
                                    <div class="center-layout">
                                        <el-input type="number" v-model="row.estimatedQuantity" v-if="row.status"></el-input>
                                        <div v-else>{{ row.productName }}</div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
            <div class="tab">
                <div class="title">
                    {{ $i18n.t('drDetails.subject5') }}
                </div>
                <div class="fill">
                    <el-form ref="form1" :model="form" :rules="rules" label-width="220px" size="mini">
                        <div class="small-title">{{ $i18n.t('drDetails.title5_1') }}</div>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_3')" prop="endCustomersName">
                                    <el-input v-model="form.endCustomersName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_4')">
                                    <el-input v-model="form.endCustimerName"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_5_1')" prop="firstName">
                                    <el-input v-model="form.firstName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_5_2')" prop="country">
                                    <el-input v-model="form.country"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_6')" prop="lastName">
                                    <el-input v-model="form.lastName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_7')">
                                    <el-input v-model="form.state"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_8')" prop="email">
                                    <el-input v-model="form.email"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_9')" prop="city">
                                    <el-input v-model="form.city"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_10')">
                                    <el-input v-model="form.officePhone"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_11')">
                                    <el-input v-model="form.town"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_12')">
                                    <el-input v-model="form.address"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_13')">
                                    <el-input v-model="form.webSite"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_14')">
                                    <el-input v-model="form.postalCode"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_15')">
                                    <el-input v-model="form.phoneNo"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_16')" prop="industry">
                                    <el-input v-model="form.industry"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_17')">
                                    <el-input v-model="form.subIndustry"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div class="small-title">{{ $i18n.t('drDetails.title5_18') }}</div>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_19')" prop="disCompanyName">
                                    <el-input v-model="form.disCompanyName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_20')">
                                    <el-input v-model="form.disAddress"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_21')">
                                    <el-input v-model="form.disLocationName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_22')">
                                    <el-input v-model="form.disPostalCode"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_23')">
                                    <el-input v-model="form.disCity"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div class="small-title">{{ $i18n.t('drDetails.title5_24') }}</div>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_25')">
                                    <el-input v-model="form.tierTwoCompanyName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_26')">
                                    <el-input v-model="form.tierTwoAddress"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_27')">
                                    <el-input v-model="form.tierTwoLocationName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_28')">
                                    <el-input v-model="form.tierTwoPostalCode"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$i18n.t('drDetails.title5_29')">
                                    <el-input v-model="form.tierTwoCity"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div class="tab">
                            <div class="title">
                                {{ $i18n.t('drDetails.subject6') }}
                            </div>
                            <div class="fill">
                                <div class="table-content">
                                    <el-table :data="drSalesTeam" style="width: 100%" height="100%">
                                        <el-table-column type="index" width="80">
                                            <template slot="header">
                                                <div style="height: 100%;">
                                                    <i class="el-icon-plus" style="font-size: 20px; font-weight: 700; color: #c7000b;" @click="addRow('drSalesTeam')"></i>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="$i18n.t('drDetails.title6_1')" width="100">
                                            <template slot-scope="{row, $index}">
                                                <div class="center-layout">
                                                    <i class="el-icon-delete" @click="deleteRow('drSalesTeam', row, $index)"></i>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="main" :label="$i18n.t('drDetails.title6_2')" width="">
                                            <template slot-scope="{row}">
                                                <div class="center-layout">
                                                    <el-input type="text" v-model="row.main" v-if="row.status"></el-input>
                                                    <div v-else>{{ row.main }}</div>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="partner" :label="$i18n.t('drDetails.title6_3')" width="80">
                                            <template slot-scope="{row}">
                                                <div class="center-layout">
                                                    <el-input type="text" v-model="row.partner" v-if="row.status"></el-input>
                                                    <div v-else>{{ row.partner }}</div>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="companyName" :label="$i18n.t('drDetails.title6_4')" width="">
                                            <template slot-scope="{row}">
                                                <div class="center-layout">
                                                    <el-input type="text" v-model="row.companyName" v-if="row.status"></el-input>
                                                    <div v-else>{{ row.companyName }}</div>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="role" :label="$i18n.t('drDetails.title6_5')" width="">
                                            <template slot-scope="{row}">
                                                <div class="center-layout">
                                                    <el-input type="text" v-model="row.role" v-if="row.status"></el-input>
                                                    <div v-else>{{ row.role }}</div>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="name" :label="$i18n.t('drDetails.title6_6')" width="">
                                            <template slot-scope="{row}">
                                                <div class="center-layout">
                                                    <el-input type="text" v-model="row.name" v-if="row.status"></el-input>
                                                    <div v-else>{{ row.name }}</div>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="email" :label="$i18n.t('drDetails.title6_7')" width="">
                                            <template slot-scope="{row}">
                                                <div class="center-layout">
                                                    <el-input type="text" v-model="row.email" v-if="row.status"></el-input>
                                                    <div v-else>{{ row.email }}</div>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="officePhone" :label="$i18n.t('drDetails.title6_8')" width="">
                                            <template slot-scope="{row}">
                                                <div class="center-layout">
                                                    <el-input type="text" v-model="row.officePhone" v-if="row.status"></el-input>
                                                    <div v-else>{{ row.officePhone }}</div>
                                                </div>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </div>
                        <el-form-item prop="accept" class="defualt">
                            <el-checkbox v-model="form.accept"></el-checkbox>{{ $i18n.t('drDetails.tips1_1') }}<strong class="link" @click="toPage">{{ $i18n.t('drDetails.link1') }}</strong>
                                <br/>{{ $i18n.t('drDetails.tips1_2') }}
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div>
                <span style="color: rgba(199, 0, 11, 0.76);"><strong>{{ $i18n.t('drDetails.tips2_1') }}</strong> {{ $i18n.t('drDetails.tips2_2') }}</span>&nbsp;&nbsp;&nbsp;&nbsp;<el-checkbox v-model="isRequest"></el-checkbox>
            </div>
            <div style="display: flex;justify-content: center;margin: 20px;">
                <el-button v-if="isAuthority" type="primary" round @click="submit">{{ $i18n.t('drDetails.submit') }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import 
{ 
    addDrDealInfo, addDrProducts, addDrProgress, addDrSalesTeam, addDrCompetitors, 
    updateDrDealInfoById, 
    deleteDrProductsById, deleteProgressById, deleteDrSalesTeamById, deleteDrCompetitorsById,
    getDrDealInfoById, getProgressByDrId, getCompetitorsByDrId, getProjectByDrId, getSalesTeamByDrId,
    getUserInfoByAccount, getCustomRelationshipByAccount
} from '@/api/dr'
 export default {
    name: 'DetailsDR',
    data() {
        let validatorEmail = (rule, value, callback) => {
            if (value.length > 254) {
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.length-error')));
            } else if (value.indexOf('@') === -1) {
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.at-error')));
            } else if (!/^(\w|[!#$%&’*+-/=?^`{}|~.])+@[^@]*$/.test(value)) {
            // invalidLetter = value.replace(/@[^@]+$/, '').replace(/\w|[!#$%&’*+-/=?^`{}|~.]/g, '');
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.account-letter-forbidden')));
            } else if (/[.]{2}/.test(value)) {
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.double-dot-error')));
            } else if (!/^.{1,63}@[^@]*$/.test(value)) {
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.account-length-error')));
            } else if (!(/(^[^.].*@[^@]*$)/.test(value) && /^.*[^.]@[^@]*$/.test(value))) {
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.prevDot-error')));
            } else if (!/^[^@]+@([0-9]|[A-Z]|[a-z]|[\-.])+$/.test(value)) {
            // invalidLetter = value.replace(/^[^@]+@/, '').replace(/[A-Za-z0–9\-.]/g, '');
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.nextLetter-forbidden')));
            } else if (!(/^[^@]+@[^-].*$/.test(value) && /^[^@]+@.*[^-]$/.test(value))) {
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.nextLine-error')));
            } else if (!/^[^@]+@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)) {
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.domain-error')));
            } else {
            callback();
            }

        };
        return {
            id: '',
            user: {},
            isRequest: false,
            drProjectProgress: [],
            drCompetitors: [],
            drProducts: [],
            drSalesTeam: [],
            companys: [],
            form: {
                dealBusinessType: 'Project',
                projectName: '',
                companyName: '',
                winOdds: '',
                expectedDate: '',
                publicTender: '',
                dealDescription: `Specific customer name:
Project name:
Budget:
Products or solutions:
Project decision-making chain:
Project implementation time:`,
                dealBackground: `Current project status：
Activity update related with End Customer：`,
                mangerEmail: '',
                endCustomersName: '',
                endCustimerName: '',
                firstName: '',
                lastName: '',
                country: '',
                state: '',
                email: '',
                city: '',
                officePhone: '',
                town: '',
                address: '',
                webSite: '',
                postalCode: '',
                phoneNo: '',
                industry: '',
                subIndustry: '',
                disCompanyName: '',
                disAddress: '',
                disLocationName: '',
                disPostalCode: '',
                disCity: '',
                tierTwoCompanyName: '',
                tierTwoAddress: '',
                tierTwoLocationName: '',
                tierTwoPostalCode: '',
                tierTwoCity: '',
                applicant: '',
                status: '',
                approvalStatus: '',
                accept: true,
                startFrom: '',
                validUntil: '',
                updateDate: ''
            },
            drProjectProgress: [],
            rules: {
                projectName: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
                companyName: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
                winOdds: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
                expectedDate: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
                dealDescription: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
                endCustomersName: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
                firstName: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
                country: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
                lastName: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
                email: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                    { validator: validatorEmail, trigger: ['blur', 'change'] },
                ],
                city: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
                industry: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
                disCompanyName: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
                accept: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ]
            },
            acceptLabel: `  `,
            isAuthority: false
        }
    },
    created() {
        if (!this.$store.state.client.user.account) {
            this.$router.push({
                path: `/login`
            })
        }
    },
    async mounted() {
        this.id = this.$route.params.id;
        this.user = {
            identity: null
        };
        if (this.$store.state.client.user.account) {
            this.user = await this.getUserInfoByAccount()
        }
        // 没ID就是新增状态，有ID就是修改状态
        if (this.id) { // 修改
            this.getDrDealInfoById(this.id)
            this.getCompetitorsByDrId(this.id)
            this.getProgressByDrId(this.id)
            this.getProjectByDrId(this.id)
            this.getSalesTeamByDrId(this.id)

            if (this.$store.state.client.user.account) {
                if (this.form.applicant === this.$store.state.client.user.account || (this.form.companyName === this.user.company) && [0,1].includes(this.user.identity) || this.user.identity === 2) {
                    this.isAuthority = true
                }
            }
        } else { // 新增
            this.form.applicant = this.$store.state.client.user.account
            if (this.user.identity === 3) {
                this.companys = await this.getCustomRelationshipByAccount();
            } else {
                this.form.companyName = this.user.company;
            }

            if (this.$store.state.client.user.account) {
                this.isAuthority = true
            }
            this.form.startFrom = new Date()
        }
    },
    watch: {
        isRequest(nVal, oVal) {
            // console.log(nVal, oVal)
            if (nVal === true) {
                this.handleClose()
            }
        },
        "form.startFrom": {
            immediate: false,
            deep: true,
            handler(nVal, oVal) {
                if (nVal !== null && !this.id) {
                   let date = new Date(nVal)
                   this.form.validUntil = new Date(date.setDate(date.getDate() + 180)).Format('yyyy-MM-dd')
                }
            }
        }
    },
    methods: {
        async getCustomRelationshipByAccount() {
            const {data: {isSuccess, data}} = await getCustomRelationshipByAccount()
            if (isSuccess) {
                let company = data.map(item => {
                    return item.company
                })
                return company;
            }
        },

        handleClose() {
            this.$confirm('Are you sure you want to request to close the deal? (If you select this option, you will not be able to modify or update the content of this deal after it is closed).')
            .then(_ => {
                this.isRequest = true
            })
            .catch(_ => {
                this.isRequest = false
            });
        },

        async getUserInfoByAccount() {
           const {data: {data, isSuccess}} = await getUserInfoByAccount({email: this.$store.state.client.user.account})
           if (isSuccess) {
            // this.form.companyName = data.company
            return data
           }
        },
        toPage() {
            const {href} = this.$router.resolve({
                path: '/dr-privacy-policy'
            })
            window.open(href, '_blank')
        },
        addRow(target) {
            switch (target) {
                case 'drProjectProgress':
                    this[target].push({
                        drId: '',
                        projectProgress: '',
                        help: '',
                        status: 'add',
                        createdDate: (new Date()).Format("yyyy-MM-dd hh:mm:ss")
                    })
                    break;
                case 'drCompetitors':
                    this[target].push({
                        drId: '',
                        competitorName: '',
                        competitorProduct: '',
                        status: 'add'
                    })
                    break;
                case 'drProducts':
                    this[target].push({
                        drId: '',
                        productType: '',
                        series: '',
                        productName: '',
                        status: 'add'
                    })
                    break;
                case 'drSalesTeam':
                    this[target].push({
                        drId: '',
                        main: '',
                        partner: '',
                        companyName: '',
                        role: '',
                        name: '',
                        email: '',
                        officePhone: '',
                        status: 'add'
                    })
                    break;
            }
        },
        deleteRow(target, row, index) {
            // 直接调用删除api，成功后直接删除这一项
            if (row.drId) {
                // 删除API
                switch (target) {
                    case 'drProjectProgress':
                    this.deleteProgressById(target, row, index)
                    break;
                case 'drCompetitors':
                    this.deleteDrCompetitorsById(target, row, index)
                    break;
                case 'drProducts':
                    this.deleteDrProductsById(target, row, index)
                    break;
                case 'drSalesTeam':
                    this.deleteDrSalesTeamById(target, row, index)
                    break;
                }
            } else {
                this[target].splice(index, 1)
            }
        },
        check(ref) {
            return new Promise((resolve, reject) => {
                this.$refs[ref].validate(valid => {
                    if (valid) {
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                })
            })
        },
        // submit
        async submit() {
            const check1 = await this.check('form')
            const check2 = await this.check('form1')
            const check3 = this.form.accept
            if (!check3) {
                this.$message.warning('Please pay attention to the Privacy Statement.')
            }
            if (!this.$store.state.client.user.account) {
                this.$message.error('Please log in to your company account (administrator account).')
                return false;
            }
            if (check1 && check2 && check3) {
                let id = '';
                this.form.updateDate = (new Date()).Format('yyyy-MM-dd'); 
                if (!this.id) {
                    // 这个是点击新增按钮的时候
                    this.form.status = 'Unclosed'
                    this.form.approvalStatus = 'To be Approved'
                    id = await this.addDrDealInfo();
                } else {
                    // 这个是点击修改按钮的时候
                    this.form.approvalStatus = 'To be Approved'
                    if (this.isRequest) {
                        this.form.status = 'Pending'
                    }
                    id = await this.alterDrDealInfoById();
                }
                if (id) {
                    // 1.DrProjectProgress
                    await this.batch(this.drProjectProgress, this.addDrProgress, id)
                    // 2.DrCompetitors
                    await this.batch(this.drCompetitors, this.addDrCompetitors, id)
                    // 3.DrProducts
                    await this.batch(this.drProducts, this.addDrProducts, id)
                    // 4.DrSalesTeam
                    await this.batch(this.drSalesTeam, this.addDrSalesTeam, id)
                    this.$router.push({
                        path: '/dr-list'
                    })
                }
            }
        },
        async batch(target, callbackInsert, drId) {
            for await (let row of target) {
                if (row.status === 'add') {
                    row['drId'] = drId
                    callbackInsert(row)
                }
            }
        },
        // 插入、DrDealInfo
        async addDrDealInfo() {
            const {data: {data, isSuccess}} = await addDrDealInfo(this.form)
            if (isSuccess) {
                return data
            }
        },
        // 插入、DrProducts
        async addDrProducts(prams) {
           const {data: {data, isSuccess}} = await addDrProducts(prams)
           if (isSuccess) {
             return data
           }
        }, 
        async deleteDrProductsById(target, row, index) {
            const {data: {data, isSuccess}} = await deleteDrProductsById(row)
            if (isSuccess) {
                this[target].splice(index, 1)
            }
        },
        // 插入、DrProjectProgress
        async addDrProgress(prams) {
            const {data: {data, isSuccess}} = await addDrProgress(prams)
            if (isSuccess) {
                return data
            }
        }, 
        async deleteProgressById(target, row, index) {
            const {data: {data, isSuccess}} = await deleteProgressById(row)
            if (isSuccess) {
                this[target].splice(index, 1)
            }
        },
        // 插入、DrSalesTeam
        async addDrSalesTeam(prams) {
            const {data: {data, isSuccess}} = await addDrSalesTeam(prams)
            if (isSuccess) {
                return data
            }
        }, 
        async deleteDrSalesTeamById(target, row, index) {
            const {data: {data, isSuccess}} = await deleteDrSalesTeamById(row)
            if (isSuccess) {
                this[target].splice(index, 1)
            }
        },
        // 插入、DrCompetitors
        async addDrCompetitors(prams) {
            const {data: {data, isSuccess}} = await addDrCompetitors(prams)
            if (isSuccess) {
                return data
            }
        },
        async deleteDrCompetitorsById(target, row, index) {
            const {data: {data, isSuccess}} = await deleteDrCompetitorsById(row)
            if (isSuccess) {
                this[target].splice(index, 1)
            }
        },
        async alterDrDealInfoById() {
            const {data: {data, isSuccess}} = await updateDrDealInfoById(this.form)
            if (isSuccess) {
                return data
            }
        },

        // 根据单号查询Deal Info
        async getDrDealInfoById(drId) {
            const {data: {data, isSuccess}} = await getDrDealInfoById(drId)
            if (isSuccess) {
                this.form = data
            }
        },
        // 根据单号查询Progress表格
        async getProgressByDrId(drId) {
            const {data: {data, isSuccess}} = await getProgressByDrId(drId)
            if (isSuccess) {
                this.drProjectProgress = data
            }
        }, 
        // 根据单号查询Competitors表格
        async getCompetitorsByDrId(drId) {
            const {data: {data, isSuccess}} = await getCompetitorsByDrId(drId)
            if (isSuccess) {
                this.drCompetitors = data
            }
        },
        // 根据单号查询Products表格
        async getProjectByDrId(drId) {
            const {data: {data, isSuccess}} = await getProjectByDrId(drId)
            if (isSuccess) {
                this.drProducts = data
            }
        }, 
        // 根据单号查询Team表格
        async getSalesTeamByDrId(drId) {
            const {data: {data, isSuccess}} = await getSalesTeamByDrId(drId)
            if (isSuccess) {
                this.drSalesTeam = data
            }
        }
    }
 }
</script>

<style lang="scss" scoped>
.dr {
    background-color: #F5F7FA;
}
.center-layout {
    display: flex;
    justify-content: center;
}
.t-container {
    background-color: #ffffff;
    width: 1200px;
    margin: 0 auto;
    padding: 10px;
    .title {
        margin-top: 10px;
        padding-left: 10px;
        color: #ffffff;
        font-size: 22px;
        background-color: #666666;
    }
    .el-row {
        &:first-child {
            margin-top: 20px;
        }
    }
    .el-col {
        margin-top: 10px;
    }
    .table-content {
        height: 300px;
    }
    .small-title {
        margin: 8px 0;
        padding-left: 10px;
        font-size: 18px;
        font-weight: bold;
    }
    .defualt {
        margin-top: 20px;
        ::v-deep .el-form-item__content {
            margin-left: 0px !important;
            white-space: pre-wrap;
        }
    }
    .link {
        border-bottom: 1px solid #000;
        cursor: pointer;
    }
}
</style>